import { MouseEvent } from 'react';

import HWIChangeValue from './change-value';
import HWITrendArrow from './trend-arrow-icon';
import determineChangeDirection from './util/determine-change-direction';
import formatChangeValue from './util/format-change-value';

type HWIStateCompareCardProps = {
  date: string
  hwiChangeValue: number
  linkUrl: string
  mapImageUrl: string
  stateName: string
  title: string
}

const HWIStateCompareCard = ({
  date,
  hwiChangeValue,
  linkUrl,
  mapImageUrl,
  stateName,
  title,
}:HWIStateCompareCardProps) => {

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (!linkUrl || (e.nativeEvent.target as HTMLElement)?.tagName?.toLowerCase() === 'a') return;

    window.open(linkUrl, '_blank');
  };

  return (
    <div className="hwi--state-compare-card" onClick={ handleClick }>
      <div className="hwi-card--title animation--slide-up-fade-in">
        {title}
        <span>{date}</span>
      </div>
      <HWITrendArrow direction={ determineChangeDirection(hwiChangeValue) } />
      <div className="hwi-compare-card--side-by-side">
        <div className="hwi-compare-side-by-side">
          <HWIChangeValue
            direction={ determineChangeDirection(hwiChangeValue) }
            label="HWI"
            value={ formatChangeValue(hwiChangeValue) }
            variant="sm"
          />
        </div>
        <img src="https://marketing-assets.wheniwork-production.com/2021/12/11224620/dashed-border.svg" aria-hidden={ true } alt="" />
        <div className="hwi-compare-side-by-side">
          <div className="hwi-state-compare-card--image animation--slide-down-fade-in">
            <a href={ linkUrl } target="_blank" rel="noreferrer">
              <img src={ mapImageUrl } alt={ `Map of ${stateName}` } />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HWIStateCompareCard;
